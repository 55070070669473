const resources = {
  ru: {
    translation: {
      heroSection: {
        title: 'SOPRANO ESCORT',
        subTitle:
          'Высокооплачиваемая работа для девушек в эскорте Европы, Азии, России, Америки, Африки и даже Австралии',
      },
      vipVacancies: {
        title: 'ВИП ВАКАНСИИ',
        text: 'Каждое эскорт-агентство проходит проверку на наличие в черном списке и отбираются только топовые, что бы предоставить самые лучшие условия работы девушек за границей',
        button: 'Смотреть вакансии',
      },
      abroadBanner: {
        title: 'КАК СТАТЬ ЭСКОРТНИЦЕЙ И НАЧАТЬ РАБОТАТЬ ЗА ГРАНИЦЕЙ',
        text: 'Работа в эскорте за границей позволяет девушкам строить свое будущее. Гибкий график работы и высокий уровень оплаты труда позволяют реализовывать различные финансовые планы и достигать личных и профессиональных целей',
        button: 'Стань участником нашего тг канала',
      },
      topCountries: {
        title: 'ТОП СТРАНЫ',
        text: 'Каждое эскорт-агентство проходит проверку на наличие в черном списке и отбираются только топовые, что бы предоставить самые лучшие условия работы девушек за границей',
        button: 'Топ страны',
      },
      purpleBanner: {
        textOne: 'ПОЛУЧИТЕ НЕЗАБЫВАЕМЫЕ ВПЕЧАТЛЕНИЯ',
        textTwo: 'ДОСТОЙНОЕ ФИНАНСОВОЕ ВОЗНАГРАЖДЕНИЕ',
        textThree: 'СОЗДАЙТЕ УСПЕШНОЕ БУДУЩЕЕ',
      },
      locations: {
        title: 'НАПРАВЛЕНИЯ',
        text: 'Высокооплачиваемая работа девушек в эскорте Европы, Азии, России, Америки, Африки и даже Австралии',
        button: 'Выбрать направление',
      },
      groups: {
        title: 'НАШИ ГРУППЫ',
        text: 'Станьте участницей наших групп, узнайте об условиях работы моделью и отзывах девушек',
        button: 'Перейти в группы',
      },
      blogBanner: {
        title: 'БЛОГ',
        text: 'Статьи на тему безопасности, поведения в туре, условиях и требованиях для девушек и многое другое',
        button: 'Смотреть наш блог',
      },
      benefitTitle: {
        benefitTitle: 'ПРЕИМУЩЕСТВА РАБОТЫ С НАМИ',
      },
      benefitContent: {
        freeTime: {
          title: 'СВОБОДНЫЙ ГРАФИК',
          text: 'Свободный график работы, что позволяет планировать свое время самостоятельно и сочетать работу с другими интересами или занятиями',
        },
        safety: {
          title: 'БЕЗОПАСНОСТЬ',
          text: 'Обеспечение безопасности – серьезные эскорт-агентства заботятся о своих сотрудницах и обеспечивают им безопасность во время работы',
        },
        privacy: {
          title: 'ПРИВАТНОСТЬ',
          text: 'Девушки могут быть уверены в том, что их личные данные и информация о работе будут храниться в тайне',
        },
        housing: {
          title: 'ПРОЖИВАНИЕ',
          text: 'Проживание за границей бесплатное, либо с очень хорошими условиями. Что позволяет наслаждаться прекрасными пейзажами и жизнью в других странах',
        },
        payment: {
          title: 'ОПЛАТА',
          text: 'Оплата в валюте, обеспечивает высокий уровень дохода и финансовую стабильность не привязанную к курсу рубля',
        },
        world: {
          title: 'ВЕСЬ МИР',
          text: 'Свободный график работы, что позволяет планировать свое время самостоятельно и сочетать работу с другими интересами или занятиями',
        },
      },
      testimonialsTitle: {
        title: 'ОТЗЫВЫ ДЕВУШЕК',
      },
      testimonialsContent: {
        text: 'Все было замечательно, я не первый раз работала в Бельгии... Встречают, провожают, всегда на связи по любому вопросу. Проживание в шикарной квартире БЕСПЛАТНО, работа в радость одним словом!',
      },
      testContTwo: {
        text: 'Привет девочки! Сегодня прибыла во Францию. Встретил личный водитель он же охранник. Поселили в 5 звездах в центре города. Очень приятный менеджер, все показал и рассказал, познакомилась с девочками и жду уже начала работы',
      },
      testContThree: {
        text: 'Девочки это Дубай! Давно хотела тут поработать, читала отзывы, что тут щедрые мужчины, но они соврали. Тут чересчур щедрые мужчины! Очень много допов и чая, хочу тут остаться подольше!',
      },
      footer: {
        text: 'не предоставляет услуги интимного характера. Все представленные вакансии, текст и изображения являются собственностью работодателя. Только для девушек от 18 лет. Сопровождение для мужчин без интима',
      },
      nav: {
        vip: 'Вип вакансии',
        top: 'Топ страны',
        world: 'Весь мир',
        groups: 'Группы',
        blog: 'Статьи',
      },
      videoNav: {
        vip: {
          title: 'ВИП ВАКАНСИИ',
          text: 'Каждое эскорт-агентства проходит проверку на наличие в черном списке и отбираются только топовые, чтобы предоставить самые лучшие условия работы девушек за границей',
          btnText: 'Смотреть вип вакансии',
        },
        top: {
          title: 'ТОП СТРАНЫ',
          text: 'Топовые страны, пользующиеся наибольшим споросом среди девушек',
          btnText: 'Выберите страну',
        },
        world: {
          title: 'ВЕСЬ МИР',
          text: 'Высокооплачиваемая работа девушек в Европе, Азии, России, Америке, Африке и даже Австралии',
          btnText: 'Выбрать направление',
        },
        groups: {
          title: 'ГРУППЫ',
          text: 'Мы обеспечиваем работу для девушек по всему Миру и предлагаем работу премиум класса',
          btnText: 'Перейти в группы',
        },
        blog: {
          title: 'СТАТЬИ',
          text: 'Статьи на тему безопасности, поведения в туре, условиях и требованиях для девушек и многое другое',
          btnText: 'Смотреть статьи',
        },
      },
      pageNav: {
        vip: {
          title: 'Вип вакансии',
          forward: 'Топ страны',
          back: 'Наши статьи',
          btnText: 'Смотреть агентство',
        },
        top: {
          title: 'Топ страны',
          forward: 'Весь мир',
          back: 'Вип вакансии',
          btnText: 'Смотреть агентства',
        },
        world: {
          title: 'Весь мир',
          forward: 'Группы',
          back: 'Топ страны',
          btnText: 'Смотреть направления',
        },
        groups: {
          title: 'Наши группы',
          forward: 'Статьи',
          back: 'Весь мир',
          btnText: 'Смотреть',
          groupsSwiper: {
            title: 'Группы',
            subTitle:
              'Высокооплачиваемая работа моделью от агентства SOPRANO ESCORT',
          },
          testimonials: {
            title: 'Отзывы',
            subTitle: 'Отзывы девушек о работе за границей',
          },
          conditions: {
            title: 'Условия',
            subTitle: 'Лучшие условия работы для девушек за границей',
          },
        },
        blog: {
          title: 'Наши статьи',
          forward: 'Вип вакансии',
          back: 'Группы',
          btnText: 'Смотреть раздел',
          dayTitle: 'Статья дня',
        },
      },
      btn: {
        title: 'Наши контакты',
        chTitle: 'Стать участником канала',
        tgTitle: 'Перейти в Telegram',
      },
      bottomNav: {
        vip: {
          left: 'статьи',
          right: 'топ страны',
        },
        top: {
          left: 'вип вакансии',
          right: 'весь мир',
        },
        world: {
          left: 'топ страны',
          right: 'группы',
        },
        groups: {
          left: 'весь мир',
          right: 'статьи',
        },
        blog: {
          left: 'группы',
          right: 'вип вакансии',
        },
      },
    },
  },
  en: {
    translation: {
      heroSection: {
        title: 'SOPRANO ESCORT',
        subTitle:
          'Highly paid work of girls in escort in Europe, Asia, Russia, America, Africa and even Australia',
      },
      vipVacancies: {
        title: 'VIP VACANCIES',
        text: 'Each escort agency is checked for blacklisting and only the top ones are selected to provide the best working conditions for girls abroad',
        button: 'Watch vacancies',
      },
      abroadBanner: {
        title: 'HOW TO BECOME AN ESCORT AND START WORKING ABROAD',
        text: 'Working as an escort abroad allows girls to build their future. Flexible work schedule and high salary allow them to implement various financial plans and achieve personal and professional goals',
        button: 'Become a member of our TG channel',
      },
      topCountries: {
        title: 'TOP COUNTRIES',
        text: 'Each escort agency is checked for blacklisting and only the top ones are selected to provide the best working conditions for girls abroad',
        button: 'Top countries',
      },
      purpleBanner: {
        textOne: 'GET AN UNFORGETTABLE EXPERIENCE',
        textTwo: 'WORTHY FINANCIAL REWARD',
        textThree: 'CREATE A SUCCESSFUL FUTURE',
      },
      locations: {
        title: 'DIRECTIONS',
        text: 'Highly paid work of girls in escort in Europe, Asia, Russia, America, Africa and even Australia',
        button: 'Select direction',
      },
      groups: {
        title: 'OUR GROUPS',
        text: 'Become a member of our groups, learn about the conditions of working as a model and reviews from girls',
        button: 'Go to groups',
      },
      blogBanner: {
        title: 'BLOG',
        text: 'Articles on safety, behavior on tour, conditions and requirements for girls and much more',
        button: 'View our blog',
      },
      benefitTitle: {
        benefitTitle: 'ADVANTAGES OF WORKING WITH US',
      },
      benefitContent: {
        freeTime: {
          title: 'FREE SCHEDULE',
          text: 'Flexible work schedule, which allows you to plan your time independently and combine work with other interests or activities',
        },
        safety: {
          title: 'SAFETY',
          text: 'Security – Serious escort agencies take care of their employees and ensure their safety during work',
        },
        privacy: {
          title: 'PRIVACY',
          text: 'Girls can be sure that their personal data and work information will be kept confidential',
        },
        housing: {
          title: 'ACCOMMODATION',
          text: 'Living abroad is free or with very good conditions. Which allows you to enjoy the beautiful landscapes and life in other countries',
        },
        payment: {
          title: 'PAYMENT',
          text: 'Payment in foreign currency ensures a high level of income and financial stability not tied to the ruble exchange rate',
        },
        world: {
          title: 'THE WHOLE WORLD',
          text: 'Flexible work schedule, which allows you to plan your time independently and combine work with other interests or activities',
        },
      },
      testimonialsTitle: {
        title: 'GIRLS REVIEWS',
      },
      testimonialsContent: {
        text: 'Everything was great, it was not my first time working in Belgium... They meet you, see you off, always in touch with any questions. Accommodation in a luxurious apartment is FREE, work is a joy, in a word!',
      },
      testContTwo: {
        text: 'Hi girls! Today I arrived in France. I was met by a personal driver who is also a security guard. They accommodated me in a 5-star hotel in the city center. A very nice manager, he showed and told me everything, I met the girls and I am already waiting to start working',
      },
      testContThree: {
        text: 'Girls, this is Dubai! I have wanted to work here for a long time, I read reviews that the men here are generous, but they lied. The men here are too generous! There are a lot of extras and tea, I want to stay here longer!',
      },
      footer: {
        text: 'does not provide services of an intimate nature. All presented vacancies, text and images are the property of the employer. Only for girls from 18 years old. Escort for men without intimacy',
      },
      nav: {
        vip: 'Vip vacancies',
        top: 'Top countries',
        world: 'Whole world',
        groups: 'Groups',
        blog: 'Blog',
      },
      videoNav: {
        vip: {
          title: 'VIP VACANCIES',
          text: 'Each escort agency is checked for blacklisting and only the top ones are selected to provide the best working conditions for girls abroad',
          btnText: 'View VIP vacancies',
        },
        top: {
          title: 'TOP COUNTRIES',
          text: 'Top Countries Most Popular Among Girls',
          btnText: 'Select country',
        },
        world: {
          title: 'THE WHOLE WORLD',
          text: 'Highly paid work for girls in Europe, Asia, Russia, America, Africa and even Australia',
          btnText: 'Select direction',
        },
        groups: {
          title: 'GROUPS',
          text: 'We provide jobs for girls all over the world and offer premium class jobs',
          btnText: 'Go to groups',
        },
        blog: {
          title: 'ARTICLES',
          text: 'Articles on safety, behavior on tour, conditions and requirements for girls and much more',
          btnText: 'View articles',
        },
      },
      pageNav: {
        vip: {
          title: 'Vip vacancies',
          forward: 'Top countries',
          back: 'Our articles',
          btnText: 'View agency',
        },
        top: {
          title: 'Top countries',
          forward: 'Whole world',
          back: 'Vip vacancies',
          btnText: 'View agencies',
        },
        world: {
          title: 'Whole world',
          forward: 'Groups',
          back: 'Top countries',
          btnText: 'View directions',
        },
        groups: {
          title: 'Our groups',
          forward: 'Articles',
          back: 'Whole world',
          btnText: 'Watch',
          groupsSwiper: {
            title: 'Groups',
            subTitle: 'Highly paid modeling job from SOPRANO ESCORT agency',
          },
          testimonials: {
            title: 'Testimonials',
            subTitle: 'Reviews of girls about working abroad',
          },
          conditions: {
            title: 'Terms and Conditions',
            subTitle: 'The best working conditions for girls abroad',
          },
        },
        blog: {
          title: 'Our articles',
          forward: 'Vip vacancies',
          back: 'Groups',
          btnText: 'View topic',
          dayTitle: 'Article of the day',
        },
      },
      btn: {
        title: 'Our contacts',
        chTitle: 'Become a channel member',
        tgTitle: 'Go to Telegram',
      },
      bottomNav: {
        vip: {
          left: 'articles',
          right: 'top countries',
        },
        top: {
          left: 'vip vacancies',
          right: 'the whole world',
        },
        world: {
          left: 'top countries',
          right: 'groups',
        },
        groups: {
          left: 'whole world',
          right: 'articles',
        },
        blog: {
          left: 'groups',
          right: 'vip vacancies',
        },
      },
    },
  },
  ua: {
    translation: {
      heroSection: {
        title: 'SOPRANO ESCORT',
        subTitle:
          'Високооплачувана робота дівчат в ескорті Європи, Азії, Росії, Америки, Африки та навіть Австралії',
      },
      vipVacancies: {
        title: 'ВІП ВАКАНСІЇ',
        text: 'Кожне ескорт-агентство проходить перевірку на наявність у чорному списку та відбираються лише топові, щоб надати найкращі умови роботи дівчат за кордоном',
        button: 'Дивитися вакансії',
      },
      abroadBanner: {
        title: 'ЯК СТАТИ ЕСКОРТНИЦЮ І ПОЧАТИ ПРАЦЮВАТИ ЗА КОРДОНОМ',
        text: 'Робота в ескорті за кордоном дозволяє дівчатам будувати своє майбутнє. Гнучкий графік роботи та високий рівень оплати праці дозволяють реалізовувати різні фінансові плани та досягати особистих та професійних цілей',
        button: 'Стань учасником нашого ТГ каналу',
      },
      topCountries: {
        title: 'ТОП КРАЇНИ',
        text: 'Каждое эскорт-агентство проходит проверку на наличие в черном списке и отбираются только топовые, что бы предоставить самые лучшие условия работы девушек за границей',
        button: 'Топ країни',
      },
      purpleBanner: {
        textOne: 'ОТРИМАЄТЕ НЕЗАБУТНІ ВРАЖЕННЯ',
        textTwo: 'ГІДНИЙ ФІНАНСОВИЙ ВИНАГОРОД',
        textThree: 'СТВОРАЙТЕ УСПІШНЕ МАЙБУТНЄ',
      },
      locations: {
        title: 'НАПРЯМКИ',
        text: 'Високооплачувана робота дівчат в ескорті Європи, Азії, Росії, Америки, Африки та навіть Австралії',
        button: 'Вибрати напрямок',
      },
      groups: {
        title: 'НАШІ ГРУПИ',
        text: 'Стати учасницею наших груп, дізнайтесь про умови роботи моделлю та відгуки дівчат',
        button: 'Перейти до груп',
      },
      blogBanner: {
        title: 'БЛОГ',
        text: 'Статті на тему безпеки, поведінки в турі, умовах та вимогах для дівчат та багато іншого',
        button: 'Дивитись наш блог',
      },
      benefitTitle: {
        benefitTitle: 'ПЕРЕВАГИ РОБОТИ З НАМИ',
      },
      benefitContent: {
        freeTime: {
          title: 'ВІЛЬНИЙ ГРАФІК',
          text: 'Вільний графік роботи, що дозволяє планувати свій час самостійно та поєднувати роботу з іншими інтересами чи заняттями',
        },
        safety: {
          title: 'БЕЗПЕКА',
          text: 'Забезпечення безпеки – серйозні ескорт-агентства піклуються про своїх співробітниць та забезпечують їм безпеку під час роботи',
        },
        privacy: {
          title: 'ПРИВАТНІСТЬ',
          text: 'Дівчата можуть бути впевнені в тому, що їхні особисті дані та інформація про роботу зберігатимуться в таємниці',
        },
        housing: {
          title: 'ПРОЖИВАННЯ',
          text: 'Проживання за кордоном безкоштовне або з дуже хорошими умовами. Що дозволяє насолоджуватися прекрасними краєвидами та життям в інших країнах',
        },
        payment: {
          title: 'ОПЛАТА',
          text: 'Оплата у валюті, забезпечує високий рівень доходу та фінансову стабільність не привязану до курсу рубля',
        },
        world: {
          title: 'ВЕСЬ СВІТ',
          text: 'Вільний графік роботи, що дозволяє планувати свій час самостійно та поєднувати роботу з іншими інтересами чи заняттями',
        },
      },
      testimonialsTitle: {
        title: 'ВІДГУКИ ДІВЧИН',
      },
      testimonialsContent: {
        text: 'Все було чудово, я не вперше працювала в Бельгії... Зустрічають, проводжають, завжди на звязку з будь-якого питання. Проживання в шикарній квартирі БЕЗКОШТОВНО, робота на радість одним словом!',
      },
      testContTwo: {
        text: 'Привіт дівчинки! Сьогодні прибула до Франції. Зустрів особистий водій він охоронець. Поселили за 5 зірок у центрі міста. Дуже приємний менеджер, все показав та розповів, познайомилася з дівчатками та чекаю вже початку роботи!',
      },
      testContThree: {
        text: 'Дівчатка це Дубай! Давно хотіла тут попрацювати, читала відгуки, що тут щедрі чоловіки, але вони збрехали. Тут надто щедрі чоловіки! Дуже багато допів та чаю, хочу тут залишитися довше!',
      },
      footer: {
        text: 'не надає послуг інтимного характеру. Усі представлені вакансії, текст та зображення є власністю роботодавця. Тільки для дівчат віком від 18 років. Супровід для чоловіків без інтиму',
      },
      nav: {
        vip: 'Віп вакансії',
        top: 'Топ країни',
        world: 'Весь світ',
        groups: 'Групи',
        blog: 'Статті',
      },
      videoNav: {
        vip: {
          title: 'ВІП ВАКАНСІЇ',
          text: 'Кожне ескорт-агентство проходить перевірку на наявність у чорному списку та відбираються тільки топові, щоб надати найкращі умови роботи дівчат за кордоном',
          btnText: 'Дивитися віп вакансії',
        },
        top: {
          title: 'ТОП КРАЇНИ',
          text: 'Топові країни, які мають найбільший попит серед дівчат',
          btnText: 'Виберіть країну',
        },
        world: {
          title: 'ВЕСЬ СВІТ',
          text: 'Високооплачувана робота дівчат у Європі, Азії, Росії, Америці, Африці та навіть Австралії',
          btnText: 'Вибрати напрямок',
        },
        groups: {
          title: 'ГРУПИ',
          text: 'Ми забезпечуємо роботу для дівчат по всьому світу та пропонуємо роботу преміум класу',
          btnText: 'Перейти до груп',
        },
        blog: {
          title: 'СТАТТІ',
          text: 'Статті на тему безпеки, поведінки в турі, умовах та вимогах для дівчат та багато іншого',
          btnText: 'Дивитися статті',
        },
      },
      pageNav: {
        vip: {
          title: 'Віп вакансії',
          forward: 'Топ країни',
          back: 'Наші статті',
          btnText: 'Дивитися агентство',
        },
        top: {
          title: 'Топ країни',
          forward: 'Весь світ',
          back: 'Віп вакансії',
          btnText: 'Дивитися агенції',
        },
        world: {
          title: 'Весь світ',
          forward: 'Групи',
          back: 'Топ країни',
          btnText: 'Дивитися напрямки',
        },
        groups: {
          title: 'Наші групи',
          forward: 'Статті',
          back: 'Весь світ',
          btnText: 'Дивитись',
          groupsSwiper: {
            title: 'ГРУПИ',
            subTitle:
              'Високооплачувана робота моделлю від агентства SOPRANO ESCORT',
          },
          testimonials: {
            title: 'Відгуки',
            subTitle: 'Відгуки дівчат про роботу за кордоном',
          },
          conditions: {
            title: 'Умови',
            subTitle: 'Найкращі умови роботи для дівчат за кордоном',
          },
        },
        blog: {
          title: 'Наші статті',
          forward: 'Віп вакансії',
          back: 'Групи',
          btnText: 'Дивитись розділ',
          dayTitle: 'Стаття дня',
        },
      },
      btn: {
        title: 'Наші контакти',
        chTitle: 'Стати учасником каналу',
        tgTitle: 'Перейти до Telegram',
      },
      bottomNav: {
        vip: {
          left: 'статті',
          right: 'топ країни',
        },
        top: {
          left: 'віп вакансії',
          right: 'весь світ',
        },
        world: {
          left: 'топ країни',
          right: 'групи',
        },
        groups: {
          left: 'весь світ',
          right: 'статті',
        },
        blog: {
          left: 'групи',
          right: 'віп вакансії',
        },
      },
    },
  },
}

export default resources
