import { Link } from 'react-router-dom'
import { motion, useCycle, AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import logo from '../assets/images/logo/red-logo.jpg'
import ava1 from '../assets/images/vacancies/ava1.jpg'
import ava2 from '../assets/images/topCountries/ava2.jpg'
import ava3 from '../assets/images/locations/ava3.jpg'
import ava4 from '../assets/images/groups/ava4.jpg'
import ava5 from '../assets/images/blog/ava5.jpg'

export default function AnimatedNavbar() {
  const [mobileNav, toggleMobileNav] = useCycle(false, true)

  const [t, i18n] = useTranslation()
  const changeLang = (lang) => {
    i18n.changeLanguage(lang)
  }

  const navlinks = [
    {
      title: t('nav.vip'),
      href: '/vipVacancies',
      ava: ava1,
    },
    {
      title: t('nav.top'),
      href: '/topCountries',
      ava: ava2,
    },
    {
      title: t('nav.world'),
      href: '/locations',
      ava: ava3,
    },
    {
      title: t('nav.groups'),
      href: '/groups',
      ava: ava4,
    },
    {
      title: t('nav.blog'),
      href: '/blogs',
      ava: ava5,
    },
  ]

  return (
    <header className='text-white w-full fixed top-0 flex justify-between items-center px-2 sm:px-6 py-1 sm:py-2 z-50 '>
      <Link to='/'>
        <div className='w-12 h-12 rounded-full bg-black/70 backdrop-blur-sm flex flex-col justify-center items-center shadow-md'>
          <img
            src={logo}
            alt='logo_img'
            className='w-11 h-11 object-cover rounded-full'
          />
        </div>
      </Link>
      <div className='relative z-50 py-[16px] px-[14px] bg-black/70 backdrop-blur-sm shadow-md rounded-full'>
        <motion.button
          animate={mobileNav ? 'open' : 'closed'}
          onClick={() => toggleMobileNav()}
          className='flex flex-col space-y-1'
        >
          <motion.span
            variants={{
              closed: { rotate: 0, y: 0 },
              open: { rotate: 45, y: 6 },
            }}
            className='w-5 h-[2px] rounded-full bg-[#ff033a] block'
          />
          <motion.span
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 },
            }}
            className='w-5 h-[2px] rounded-full bg-[#ff033a] block'
          />
          <motion.span
            variants={{
              closed: { rotate: 0, y: 0 },
              open: { rotate: -45, y: -6 },
            }}
            className='w-5 h-[2px] rounded-full bg-[#ff033a] block'
          />
        </motion.button>
      </div>
      <AnimatePresence>
        {mobileNav && (
          <motion.div
            key='mobile-nav'
            variants={{
              open: { x: '0%' },
              closed: { x: '100%' },
            }}
            initial='closed'
            animate='open'
            exit='closed'
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 40 },
              opacity: { duration: 0.2 },
            }}
            className='fixed right-0 top-0 w-3/4 md:w-1/2 lg:w-1/3 z-40'
          >
            <div
              onClick={() => toggleMobileNav()}
              className='flex flex-col justify-center items-end sm:items-start gap-5 pr-4 sm:pl-3 h-screen w-full shadow-black shadow-2xl bg-black/80 backdrop-blur-md'
            >
              <div className='text-white/60 font-light flex items-center gap-2 pr-2 pl-8'>
                <div
                  onClick={() => changeLang('ru')}
                  className='flex items-center gap-2'
                >
                  <p className='cursor-pointer'>RU</p>
                  <span>/</span>
                </div>
                <div
                  onClick={() => changeLang('en')}
                  className='flex items-center gap-2'
                >
                  <p className='cursor-pointer'>EN</p>
                  <span>/</span>
                </div>
                <div
                  onClick={() => changeLang('ua')}
                  className='flex items-center gap-2'
                >
                  <p className='cursor-pointer'>UA</p>
                </div>
              </div>
              {navlinks.map(({ href, title, ava }) => {
                return (
                  <Link
                    key={title}
                    to={href}
                    className='text-white font-bold hover:text-neutral-400 transition-all duration-300 sm:pl-6'
                  >
                    <div className='flex items-center gap-2 sm:gap-4 bg-black/50 py-1 px-2 rounded-full'>
                      <img
                        src={ava}
                        alt='ava_img'
                        className='object-cover w-10 sm:w-14 h-10 sm:h-14 rounded-full'
                      />
                      <h1 className='text-sm sm:text-lg font-normal tracking-widest pr-4'>
                        {title}
                      </h1>
                    </div>
                  </Link>
                )
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  )
}
