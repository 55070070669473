import { Suspense, lazy } from 'react'
import RenderOnViewportEntry from './hooks/useRenderViewport'
import { Route, Routes } from 'react-router-dom'

// const HeroSection = lazy(() => import('./components/Hero'))
const VideoNavigation = lazy(() => import('./components/VideoNav'))
const VipVacanciesBanner = lazy(() => import('./components/VacanciesBanner'))
const WorkAbroadBanner = lazy(() => import('./components/AbroadBanner'))
const TopCountriesBanner = lazy(() => import('./components/TopCountriesBanner'))
const PurpleBanner = lazy(() => import('./components/PurpleBanner'))
const CountriesBanner = lazy(() => import('./components/CountriesBanner'))
const GroupsBanner = lazy(() => import('./components/GroupsVideoBanner'))
const BlogBanner = lazy(() => import('./components/BlogBanner'))
const Benefits = lazy(() => import('./components/BenefitsGrid'))
const Testimonials = lazy(() => import('./components/Testimonials'))
const Footer = lazy(() => import('./components/Footer'))

const VipVacancies = lazy(() => import('./pages/VipVacancies'))
const TopCountries = lazy(() => import('./pages/TopCountries'))
const Locations = lazy(() => import('./pages/Locations'))
const Groups = lazy(() => import('./pages/Groups'))
const Conditions = lazy(() => import('./pages/Conditions'))
// const Community = lazy(() => import('./pages/Community'))
const Testimon = lazy(() => import('./pages/Testimon'))
const Admin = lazy(() => import('./pages/Admin'))
const Blogs = lazy(() => import('./pages/Blogs'))
const CategoryBlogs = lazy(() => import('./pages/CategoryBlogs'))
const SingleBlog = lazy(() => import('./pages/SingleBlog'))
const DailyBlog = lazy(() => import('./pages/DailyBlog'))
const Agency = lazy(() => import('./pages/Agency'))
const VipAgency = lazy(() => import('./pages/VipAgency'))
const LocationCountries = lazy(() => import('./pages/LocationCountries'))
const CountryAgencies = lazy(() => import('./pages/CountryAgencies'))
const TopCountryAgencies = lazy(() => import('./pages/TopCountryAgencies'))
const TopCountryAgency = lazy(() => import('./pages/TopCountryAgency'))

function App() {
  return (
    <main>
      <Routes>
        <Route
          path='/'
          element={
            <>
              {/* <RenderOnViewportEntry
                threshold={0.05}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <HeroSection />
              </RenderOnViewportEntry> */}
              <RenderOnViewportEntry
                threshold={0.05}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <VideoNavigation />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.05}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <VipVacanciesBanner />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.05}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <WorkAbroadBanner />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.05}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <TopCountriesBanner />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.05}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <PurpleBanner />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.05}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <CountriesBanner />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.05}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <GroupsBanner />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.05}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <BlogBanner />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.05}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <Benefits />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.05}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <Testimonials />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.05}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <Footer />
              </RenderOnViewportEntry>
            </>
          }
        />
        <Route
          path='/vipVacancies'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <VipVacancies />
            </Suspense>
          }
        />
        <Route
          path='/vipVacancies/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <VipAgency />
            </Suspense>
          }
        />
        <Route
          path='/vacancies/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <Agency />
            </Suspense>
          }
        />
        <Route
          path='/topCountries'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <TopCountries />
            </Suspense>
          }
        />
        <Route
          path='/topCountryAgencies/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <TopCountryAgencies />
            </Suspense>
          }
        />
        <Route
          path='/topCountryAgency/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <TopCountryAgency />
            </Suspense>
          }
        />
        <Route
          path='/locations'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <Locations />
            </Suspense>
          }
        />
        <Route
          path='/locationCountries/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <LocationCountries />
            </Suspense>
          }
        />
        <Route
          path='/countryAgencies/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <CountryAgencies />
            </Suspense>
          }
        />
        <Route
          path='/groups'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <Groups />
            </Suspense>
          }
        />
        <Route
          path='/groups/conditions'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <Conditions />
            </Suspense>
          }
        />
        {/* <Route
          path='/groups/community'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <Community />
            </Suspense>
          }
        /> */}
        <Route
          path='/groups/admin'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <Admin />
            </Suspense>
          }
        />
        <Route
          path='/groups/testimonials'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <Testimon />
            </Suspense>
          }
        />
        <Route
          path='/blogs'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <Blogs />
            </Suspense>
          }
        />
        <Route
          path='/blogs/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <CategoryBlogs />
            </Suspense>
          }
        />
        <Route
          path='/singleBlog/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <SingleBlog />
            </Suspense>
          }
        />
        <Route
          path='/dailyBlog/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <DailyBlog />
            </Suspense>
          }
        />
      </Routes>
    </main>
  )
}

export default App
